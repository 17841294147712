import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {importProvidersFrom} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {BrowserModule, HAMMER_GESTURE_CONFIG, bootstrapApplication} from '@angular/platform-browser';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, withHashLocation} from '@angular/router';
import 'hammerjs';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppComponent} from './app/app.component';
import {ToastService} from './app/components/global/snackbar/toast.service';
import {CustomHammerConfig} from './app/custom-gesture-config';
import {AuthInterceptor} from './app/interceptors/token.interceptor';
import {AppRoutes} from './app/routes/app.router';
import {AuthService} from './app/services/auth.service';
import {UsersService} from './app/services/users.service';

window.onload = function () {
	bootstrapApplication(AppComponent, {
		providers: [
			importProvidersFrom(BrowserModule, BrowserAnimationsModule, HttpClientModule, MatBottomSheetRef, ReactiveFormsModule, NgxSpinnerModule),
			provideRouter(AppRoutes),
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AuthInterceptor,
				multi: true,
			},
			{provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig},
			...inAppServices,
			provideAnimations(),
			provideAnimations(),
		],
	}).catch(err => console.error(err));
};

const inAppServices = [MessageService, AuthService, UsersService, ToastService, ConfirmationService];

<p-toast />



<div [style.paddingRight]="getMainContentPadding()">
	@if(authService.isLoggedIn && !isPublicUrl && !isPrintMode){
	<header class="header">
		<div class="w-full flex justify-between items-center">
			<sidebar />
		
			<div class="w-full flex justify-end">
				<logo />
			</div>
		</div>
	</header>
}
	<main class="content">
        <router-outlet #outlet="outlet"></router-outlet>
    </main>
</div>

<!-- ----------------------------------------- Global Toast Container ----------------------------------------- -->
<!--Generic-->
<p-toast
	#MessageAndSubToast
	key="MessageAndSubToast"
	[baseZIndex]="10000"
></p-toast>

<!--Only Message-->
<p-toast
	#MessageToast
	key="MessageToast"
	[baseZIndex]="10000"
>
	<ng-template
		let-message
		pTemplate="message"
	>
		<div class="flex items-center gap-4">
			<i [class]="message?.icon"></i>
			<div class=" text-lg font-bold">{{ message.summary }}</div>
		</div>
	</ng-template>
</p-toast>






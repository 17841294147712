import {Injectable} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		// Get the base URL without query parameters
		const baseUrl = req.url.split('?')[0];

		// Check if this is a direct request to S3 (not our API)
		const isDirectS3Request = this.isDirectS3Request(baseUrl);

		if (isDirectS3Request) {
			// For direct S3 requests, remove authorization
			const cleanRequest = req.clone({
				headers: req.headers.delete('Authorization'),
			});
			return next.handle(cleanRequest);
		}

		// For all other requests (including our API endpoints), add the authorization token
		const authToken = localStorage.getItem('token');
		const authReq = req.clone({
			headers: req.headers.set('Authorization', `Bearer ${authToken}`),
		});
		return next.handle(authReq);
	}

	private isDirectS3Request(url: string): boolean {
		// Only consider it an S3 request if the URL starts with the S3 domain
		// This way, S3 URLs that are part of our API paths won't match
		const s3Patterns = ['https://s3.amazonaws.com', 'https://quickly-assets-images.s3', 'https://deal360-images.s3'];

		return s3Patterns.some(pattern => url.toLowerCase().startsWith(pattern));
	}
}

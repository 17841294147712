export const GlobalApi = {
	//url: "http://localhost:3000/api",
	//url: "https://poweragent.onrender.com/api",
	//url: "https://squid-app-pxahb.ondigitalocean.app/api",
	//url: "https://vps-cfbdc45d.vps.ovh.net/api", // OVH,
	//url: "https://poweragent.mendihome.online/api",
	url: 'https://deal360.co.il/api',
	//url: 'http://localhost:3000/api',
} as const;
export type CycleServiceError = (typeof GlobalApi)[keyof typeof GlobalApi] | keyof typeof GlobalApi;
